import React from 'react';

import { Root } from './lign.styled';

export default function Lign () {
    return (
        <Root>
            
        </Root>
    );
};